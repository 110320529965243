<template>
  <div>
    <p class="text-2xl mb-6">Historial Compras</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="cols">
            <dos-fechas
              :fechas="[filtros.fecha_desde, filtros.fecha_hasta]"
              @getFechas="getFechas"
              :icon="true"
            ></dos-fechas>
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="show = !show">
              <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-expand-transition>
              <div v-show="show"> 
                <v-row>
                  <v-col cols="4">
                    <select-puesto
                      :storePuesto="true"
                      v-model="filtros.id_puesto"
                      :add="false"
                      :dense="true"
                      :outlined="true"
                      :clearable="true"
                      :ind_principal="true"
                       :filtros="{id_reporte:43}"
                    ></select-puesto>
                  </v-col>
                  <v-col cols="4">
                    <BuscarProveedor
                      ref="BuscarProveedorRef"
                      :label="`Proveedor`"
                      class="mb-4"
                      @GetProveedor="GetProveedor"
                      :add="false"
                    />
                  </v-col>
                  <v-col cols="4">
                    <BuscarEmpleadoId :label="'Empleado Crea'" v-model="filtros.id_empleado" />
                  </v-col>
                  <v-col cols="4">
                    <BuscarEmpleadoId :label="'Empleado Anula'" v-model="filtros.id_empleado_anula" />
                  </v-col>
                  <v-col cols="4">
                    <BuscarEmpleadoId :label="'Empleado Valida'" v-model="filtros.id_empleado_valida" />
                  </v-col>

                  <v-col cols="4">
                    <EstadoSelect
                      :clearable="true"
                      :label="'Estado'"
                      :dense="true"
                      :outlined="true"
                      :add="false"
                      :padre="20"
                      v-model="filtros.id_estado"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-row class="mt-1 px-3">
                      <v-select
                        clearable
                        v-model="filtros.filt_cantidad"
                        :items="filterTotales"
                        hide-details
                        dense
                        outlined
                        label="Cantidad Tipo"
                      ></v-select>
                      <v-text-field
                        clearable
                        v-model="filtros.filt_cantidad_desde"
                        hide-details
                        label="Desde"
                        dense
                        outlined
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        clearable
                        v-model="filtros.filt_cantidad_hasta"
                        hide-details
                        label="Hasta"
                        dense
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="GetVer"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
          :fontSize="4"
          :acciones="['VER']"
          :height="600"
        ></tabla-reporte>
      </v-card-text>
      <ModalViewCompra :VerButton="false"  ref="ModalViewCompraRef"></ModalViewCompra>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronUp, mdiChevronDown } from '@mdi/js'
import TablaReporte from '@/components/TablaSimpleReporte.vue'
import { ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuestoMulti.vue'
import CompraServices from '@/api/servicios/inventario/CompraServices'
import moment from 'moment'
import store from '@/store'
import DatePicher from '@/components/DatePicker.vue'
import ComandaService from '@/api/servicios/ComandaService'
import DosFechas from '@/components/DosFechas.vue'
import BuscarEmpleadoId from '../../../../administracion/archivo/empleado/components/BuscarEmpleadoId.vue' 
import EstadoSelect from '../../../../administracion/archivo/estado/componentes/Select.vue'
import BuscarProveedor from '../../proveedor/componentes/BuscarProveedor.vue'
import ModalViewCompra from '../../factura/componentes/ModalViewCompra.vue'
// demos

export default {
  components: {
    TablaReporte,
    DatePicher,
    SelectPuesto,
    DosFechas,
    BuscarEmpleadoId,
    EstadoSelect,
    BuscarProveedor,
    ModalViewCompra
  },
  setup() {
    const nombreReporte = ref(`Comadas por producto `)
    const show = ref(false)
    const filterTotales = ['Total', 'SubTotal', 'Impuesto', 'IVA']
    const openModalComanda = ref(false)
    const comandaSelect = ref(null)
    const BuscarProveedorRef = ref(null)
    const GetProveedor = item => {
     
      if ( item ) {
        filtros.value.id_proveedor = item.id
      }else{
        filtros.value.id_proveedor = null
      }
     
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }
    const cabeceraExcel = ref([
      {
        puesto: 'Locatario/Area',
        desde: 'Desde',
        hasta: 'Hasta',
        nombre: 'Producto',
        cant: 'Cant',
        total: 'Total',
      },
    ])

    const cabecera = ref([
      { text: 'Fecha', value: 'fecha', format: { small: true } },
      { text: 'Hora', value: 'hora', format: { small: true } },
      { text: 'Id', value: 'id', format: { small: true } },
      { text: 'Area', value: 'nombre', format: { small: true } },
      { text: 'Almacen', value: 'descripcion', format: { small: true } },
      { text: 'Numero Documento', value: 'numero_documento', format: { small: true } },
      { text: 'Fecha Factura', value: 'fecha_factura', format: { small: true } },
      { text: 'Nro Factura', value: 'numerofactura', format: { small: true } },
      { text: 'Nro Control', value: 'numerocontrol', format: { small: true } },
      { text: 'Doc Proveedor', value: 'documento', format: { small: true } },
      { text: 'Proveedor', value: 'nombre_proveedor', format: { small: true } },
      { text: 'Estado', value: 'estado_descripcion', format: { small: true } },
      { text: 'Empleado Crea', value: 'empleado_crea', format: { small: true } },
      { text: 'Empleado Anula', value: 'empleado_anula', format: { small: true } },
      { text: 'Empleado Valida', value: 'empleado_valida', format: { small: true } },
      { text: 'Observacion', value: 'observacion', format: { small: true } },
      { text: 'Sub Total', value: 'subtotal', format: { small: true } },
      { text: 'Otros Impuesto', value: 'impuesto', format: { small: true } },
      { text: 'Total IVA', value: 'iva', format: { small: true } },
      { text: 'Total', value: 'total', format: { small: true } },

      { text: 'Excento', value: 'excento', format: { small: true } },
      { text: 'Base IVAR', value: 'base_reducido', format: { small: true } },
      { text: 'IVAR', value: 'iva_reducido', format: { small: true } },
      { text: 'Base IVAG', value: 'base_general', format: { small: true } },
      { text: 'IVAR', value: 'iva_general', format: { small: true } },
      { text: 'Base IVAA', value: 'base_adicional', format: { small: true } },
      { text: 'IVAA', value: 'iva_adicional', format: { small: true } },
      { text: 'Base IVA OTROS', value: 'base_otros', format: { small: true } },
      { text: 'IVAO', value: 'iva_otros', format: { small: true } },
    ])
    const datos = ref([])
    const fechas = ref(null)
    const cargando = ref(false)
    const puesto = ref({})
    const filtros = ref({
      fecha_desde: moment().format('YYYY-MM-DD'),
      fecha_hasta: moment().format('YYYY-MM-DD'),
      id_empleado: null,
      id_empleado_anula: null,
      id_empleado_valida: null,
      id_estado: null,
      id_puesto: null,
      filt_cantidad: null,
      filt_cantidad_desde: null,
      filt_cantidad_hasta: null,
      id_proveedor: null,
    })
    const validar = () => {
      let validado = true
      if (puesto.value.id == undefined) {
        validado = false
      }
      if (fechas.value == undefined) {
        validado = false
      }

      return validado
    }
    const ModalViewCompraRef = ref(false)
    const GetVer = item => {
      console.log(item.id) 
      ModalViewCompraRef.value.abrir(item.id)
    }
    const getFechas = datos => {
      console.log(datos)
      filtros.value.fecha_desde = datos.desde
      filtros.value.fecha_hasta = datos.hasta
    }
    const buscarDatos = () => {
      cargando.value = true
      const params = JSON.parse(JSON.stringify(filtros.value))
      if (params.filt_cantidad) {
        params.filt_cantidad = params.filt_cantidad.toLowerCase()
      }
      if (params.id_puesto) {
        params.id_puesto = params.id_puesto.join(',')
      }
      CompraServices.reporteHistorialCompras(params)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos.compras
            nombreReporte.value = `Historial Compras  ${filtros.value.fecha_desde}  al ${filtros.value.fecha_hasta}`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    const getPuesto = datos => {
      puesto.value = datos
    }
    return {
      cabecera,
      datos,
      getPuesto,
      fechas,
      puesto,
      validar,
      buscarDatos,
      cargando,
      cabeceraExcel,
      nombreReporte,
      filtros,
      getFechas,
      show,
      filterTotales,
      GetVer,
      icons: {
        mdiChevronUp,
        mdiChevronDown,
      },
      openModalComanda,
      comandaSelect,
      GetOpenModalComanda,
      BuscarProveedorRef,
      GetProveedor,
      ModalViewCompraRef
    }
  },
}
</script>
